

import AuthGuard from './components/authentication/AuthGuard';
import LoadingScreen from './components/LoadingScreen';
import { lazy, Suspense } from 'react';


const Loadable = Component => props => <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
</Suspense>; // Landing page


const LandingHome = Loadable(lazy(() => import('./components/Layouts/LandingPage/LandingHome')));
const LandingLayout = Loadable(lazy(() => import('./components/Layouts/LandingPage/LandingLayout')));
const LandingAbout = Loadable(lazy(() => import('./components/Layouts/LandingPage/LandingAbout')));
const LandingSignup = Loadable(lazy(() => import('./components/Layouts/LandingPage/LandingSignup')));
const DashboardLayout = Loadable(lazy(() => import('./components/Layouts/DashboardLayout')));

// ShopCentre
const ShopDashboard = Loadable(lazy(() => import('./components/Pages/Shop/Dashboard')));
const ShopProfile = Loadable(lazy(() => import('./components/Pages/Shop/Profile')));
const ShopAddress = Loadable(lazy(() => import('./components/Pages/Shop/Address')));
const ShopAccount = Loadable(lazy(() => import('./components/Pages/Shop/Account')));
const ShopAdditem = Loadable(lazy(() => import('./components/Pages/Shop/Additem')));
const ShopProduct = Loadable(lazy(() => import('./components/Pages/Shop/Product')));
const ShopPromotion = Loadable(lazy(() => import('./components/Pages/Shop/Promotion')));

// dealCentre
const DashboardOffer = Loadable(lazy(() => import('./components/Pages/TradingCentre/Dashboard')));
const AddOffer = Loadable(lazy(() => import('./components/Pages/TradingCentre/AddOffer')));
const DataTable = Loadable(lazy(() => import('./components/Pages/TradingCentre/DataTable')));

// account
const AccountSetting = Loadable(lazy(() => import('./components/Pages/Account/Settings')));


const Login = Loadable(lazy(() => import('./components/Layouts/LandingPage/LandingLogin')));
const Home = Loadable(lazy(() => import('./components/Pages/Home')));
const Error = Loadable(lazy(() => import('./components/Pages/404'))); // routes

const routes = [{
    path: '/',
    element: <LandingLayout />,
    children: [{
        path: '',
        element: <LandingHome />
    }, {
        path: 'About',
        element: <LandingAbout />

    },]

}, {
    path: 'Signup',
    element: <LandingSignup />
}, {
    path: 'login',
    element: <Login />
}, {
    path: 'Homepage',
    element:
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>,
    children: [{
        path: '',
        element: <Home />
    }, {
        // ####### SHOP #######
        path: 'shop/dashboard',
        element: <ShopDashboard />
    }, {
        path: 'shop/profile',
        element: <ShopProfile />
    }, {
        path: 'shop/address',
        element: <ShopAddress />
    }, {
        path: 'shop/account',
        element: <ShopAccount />
    }, {
        path: 'shop/additem',
        element: <ShopAdditem />
    }, {
        path: 'shop/product',
        element: <ShopProduct />
    }, {
        path: 'shop/promotion',
        element: <ShopPromotion />
    }, {
        // ####### OFFER #######
        path: 'offer/board',
        element: <DashboardOffer />
    }, {
        path: 'offer/create',
        element: <AddOffer />
    }, {
        path: 'offer/report',
        element: <DataTable />
    }, {
        path: 'account/settings',
        element: <AccountSetting />
    },
    ]
}, {
    path: '*',
    element: <Error />
}];
export default routes;