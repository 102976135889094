/* eslint-disable */
//----> List Import 
import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Toaster } from "react-hot-toast";
import routes from "./routes";

//----> config theme
import { ukoTheme } from "./theme";
import useSettings from "./hooks/useSettings";


export default function App() {
  const { settings } = useSettings(); // App theme
  const allPages = useRoutes(routes);
  const appTheme = ukoTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "lg_webfont"
    }
  };

  return (
    <>

      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Toaster toastOptions={toasterOptions} />
        {allPages}
      </ThemeProvider>
    </>
  );

}
