import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import { Provider } from "react-redux";
import { createStore } from "redux";
//---------------------
import './index.css';
import App from './App';
import "nprogress/nprogress.css";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import thLocale from 'date-fns/locale/th';
import SettingsProvider from "./contexts/SettingsContext";
import TitleContextProvider from "./contexts/TitleContext";
import rootReducer from "./reducers";

const store = createStore(rootReducer); //,applyMiddleware(logger)

const AppWithRouter = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={thLocale}>
    <SettingsProvider>
      <TitleContextProvider>
        <Provider store={store}>
          <BrowserRouter>
            <CssBaseline />
            <App />
          </BrowserRouter>
        </Provider>
      </TitleContextProvider>
    </SettingsProvider>
  </LocalizationProvider>
)

//----> createRoot
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<AppWithRouter />);


