const initiaState = {
  industry: "",
  nickname: "",
  avatar: "",
  mailbox: 0
};

const ProfileReducer = (state = initiaState, { type, payload }) => {
  switch (type) {
    case "SETUP_PROFILE":
      return {
        ...state,
        industry: payload.industry,
        nickname: payload.nickname,
        avatar: payload.avatar,
        mailbox: payload.mailbox,
      };
    default:
      return state;
  }
};



export default ProfileReducer;
