
import React, { Fragment } from "react";
import { Navigate } from "react-router-dom"; // component props interface

const AuthGuard = ({ children }) => {
  const isAuthenticated = window.localStorage.getItem('settings');

  if (!isAuthenticated) {
    return <Navigate to="/Login" />;
  }

  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;